import { Component, Prop, Vue } from 'vue-property-decorator';

import WithRender from './n-modal-footer.html';

@WithRender
@Component({})
export default class NModalFooter extends Vue {
  @Prop({ default: '' })
  public wrapperClass!: string;
}
