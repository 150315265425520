<div
  :id="cardId"
  @click="onCardClick"
  :class="[
    'n-shadow-md',
    styles.card,
    cardClassName,
    { [styles.clickable]: clickable },
    'n-p-5 n-flex n-flex-column'
  ]"
>
  <div class="n-flex n-flex-column n-gap-6 n-flex-grow">
    <div v-if="iconName || image || showImagePlaceholder" :class="styles.imageContainer">
      <n-icon
        v-if="iconName"
        :name="iconName"
        :class="iconClass"
        size="55"
      />

      <img
        v-else
        :src="image || require('/public/img/img-placeholder.jpg')"
        alt="Image"
        loading="lazy"
      >
    </div>

    <h6 :class="[styles.title ,'n-font-bold n-m-0 n-break-word n-text-h6']">
      {{ title }}
    </h6>

    <p :class="[styles.description , 'n-font-normal n-text-body-default n-m-0 n-flex-grow']">
      {{ description }}
    </p>

    <slot name="actions" v-if="enableActions">
      <n-btn
        :class="[styles.navigation, 'n-p-0 n-flex n-align-center n-text-body-md']"
        @click="onActionClick()"
        :id="cardActionId"
        variant="text"
        append-icon="n-icon-arrow-left-2"
      >
        {{$t('useTheService')}}
      </n-btn>
    </slot>
  </div>
</div>
