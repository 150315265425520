import { Component, Vue, Watch } from 'vue-property-decorator';
import styles from './breadcrumb.module.scss';
import WithRender from './breadcrumb.html';
// models
import { BreadCrumbModel } from '@/models/breadcrumb';
import { BreadcrumbId } from '@/services/staticLookups';

@WithRender
@Component
export default class BreadCrumb extends Vue {
  public styles = styles;
  public showBreadCrumb = false;
  public breadcrumbId = BreadcrumbId;

  public baseBreadcrumb: BreadCrumbModel[] = [
    {
      text: this.$t('home').toString(),
      href: '/',
    },
  ];

  public breadcrumbs: BreadCrumbModel[] = this.baseBreadcrumb;

  public get isHighlighted() {
    const { path, meta } = this.$route;
    const dynamicallyHighlightedFromRouter = meta?.highlighted || false;
    const highlightedRoutes = ['/e-services', '/terms-conditions', '/privacy-policy', '/faqs', '/faq'];

    return highlightedRoutes.includes(path.toLocaleLowerCase()) || dynamicallyHighlightedFromRouter;
  }

  @Watch('$route')
  public updateBreadCrumbs() {
    this.showBreadCrumb = this.$route.fullPath !== '/' && !this.$route.fullPath.startsWith('/#');

    let enhancedBreadcrumbs: BreadCrumbModel[] = [...this.baseBreadcrumb];

    this.$route.matched.forEach((route) => {
      const hasBreadcrumb = 'breadcrumb' in route.meta;

      if (hasBreadcrumb) {
        enhancedBreadcrumbs = enhancedBreadcrumbs.concat(route.meta.breadcrumb);
      } else {
        enhancedBreadcrumbs = [...this.baseBreadcrumb];
      }
    });

    // ? update [disabled/external] prop
    this.breadcrumbs = enhancedBreadcrumbs.map((route, index) => ({
      ...route,
      external: route.external || false,
      disabled: index === (enhancedBreadcrumbs.length - 1),
    }));
  }

  public onClickBreadcrumb(route: BreadCrumbModel) {
    this.$router.push(route.href);
  }
}
