import Vue from 'vue';
import './registerServiceWorker';
import router from './router';

import { VueDiContainer } from 'vue-di-container';

// Plugins
import vuetify from './plugins/vuetify';
import i18n from './plugins/i18n';
import coreUI from '@moj/najiz-mui';
// Components
import App from './views/App/app';
// styles
import '../public/styles/main.scss';
import LoaderService from './services/loader';
import initializeApplication from './plugins/application-initialization';
import FeatureToggleService from '@/services/featureToggle';
// ! Core UI Components
import ServiceCard from './components/ServiceCard/serviceCard';
import NModal from './components/core-ui/Modal/NModal/n-modal';
import NModalHeader from './components/core-ui/Modal/NModalHeader/n-modal-header';
import NModalFooter from './components/core-ui/Modal/NModalFooter/n-modal-footer';
import NModalContent from './components/core-ui/Modal/NModalContent/n-modal-content';
import trackingController, { TrackerElement } from '@moj/najiz-tracking';
import HttpService from '@/services/httpService';
import http from '@moj/najiz-http';
import TelemetryService from './services/TelemetryService';

Vue.component('TrackerElement', TrackerElement);
Vue.component('ServiceCard', ServiceCard);
Vue.component('NModal', NModal);
Vue.component('NModalContent', NModalContent);
Vue.component('NModalHeader', NModalHeader);
Vue.component('NModalFooter', NModalFooter);

Vue.config.productionTip = false;
Vue.config.devtools = process.env.NODE_ENV !== 'production';
Vue.use(VueDiContainer);
Vue.use(coreUI);

declare module 'vue/types/vue' {
  interface Vue {
    $appInsights: any;
  }
}

declare global {
  interface Window {
    $config: any;
    $disableNewRequestsPeriod: any;
    showLoader(): void;
    hideLoader(): void;
    ApplePaySession: any,
  }
}

initializeApplication().then(() => {
  Vue.use(trackingController, {
    router,
    http,
    appInfo: window.$config.ApplicationInsights,
  });
  new Vue({
    i18n,
    router,
    vuetify,
    diProvide: [
      HttpService,
      LoaderService,
      TelemetryService,
      FeatureToggleService,
    ],
    render: (h) => h(App),
  }).$mount('#app');
});
