import { Service } from 'vue-di-container';

@Service()
export default class LoaderService {
  public showLoader(): void {
    window.showLoader();
  }

  public hideLoader(): void {
    window.hideLoader();
  }
}
