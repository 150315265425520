import { BreadCrumbModel } from '@/models/breadcrumb';
import i18n from '@/plugins/i18n';

const Breadcrumbs = {
  services: [{
    text: i18n.t('services.title'),
    href: '/e-services',
  }] as BreadCrumbModel[],

  serviceDetails: [
    {
      text: i18n.t('services.title'),
      href: '/e-services',
    },
    {
      text: i18n.t('services.explaination'),
      href: '/e-services',
    },
  ] as BreadCrumbModel[],

  faqs: [{
    text: i18n.t('faqs.title'),
    href: '/faqs',
  }] as BreadCrumbModel[],

  searchResult: [{
    text: i18n.t('searchResult'),
    href: '/',
  }] as BreadCrumbModel[],
};

export default Breadcrumbs;
