const ar = {
  home: 'Home',
  najizPortal: 'Najiz Portal',
  judicalEServices: 'The unified Judical electronic services',
  enter: 'Enter',
  noServicesFoundPleaseTryAgain: 'No services found.',
  new: 'New',
  modified: 'Modified',
  redirectToService: 'Redirect to Service',
  businessAccountActivationRequirements: 'The most important requirements for activating a business account',
  accountValidityPeriod: 'Account validity period',
  fromActivationDate: 'From activation date',
  accountCreationFees: 'Account creation fees',
  activateFirstOrganizationAccountText: 'Activate the first account of the organization',
  activateFirstOrganizationAccountDescription: 'Additional accounts will be charged.',
  free: 'free',
  sar: 'SAR',
  year: 'Year',

  verificationServices: {
    title: 'Verification services',
    description: 'This service enables you to verify documents issued by the Ministry of Justice electronically',
    userNeed: 'I want to check',
    browseVerificationServices: 'Browse verification services',
  },

  appInsights: {
    verificationServicesArName: 'Verification Services',
  },

  serviceUsage: 'Service usage',
  mostUsed: 'Most used',
  serviceExplaination: 'Service explaination',
  najizData: 'Najiz data',
  last7Days: 'last 7 days',
  najizDataDescription: 'The ideal solution for requesting reliable data and creating new business opportunities',
  najizDataLogin: 'Login to Najez Data',
  scrollMore: 'Scroll More',
  najizInNumbers: 'Najiz in numbers',

  services: {
    title: 'E-services',
    explaination: 'Service Explaination',
    browseAllServices: 'Browse all services',
    searchInServices: 'Search in services',
    prominentServices: 'Prominent Services',

  },

  licenseType: 'License type',
  chooseLicenseType: 'Choose license type',
  iWantToPreview: 'I want to preview',

  faqs: {
    title: 'Faqs',
    searchPlaceholder: 'Search here, for example: How to create a claim sheet',
    howCanWeHelpYou: 'How can we help you ?',
    browseAllQuestions: 'Browse all questions',
    noQuestionsAvailable: 'No questions available.',
    shareQuestion: 'Share question',
    questionLinkCopiedSuccessfully: 'Question link copied successfully',
    questionDetails: 'Question details',
    questionDetailsNotAvailable: 'Question details not available',
  },

  najizPartners: 'Najiz partners',

  contactUs: {
    title: 'Contact us',
    forMoreContacts: 'For more contacts',
  },
  mobileApplication: {
    title: 'Najiz app',
    downloadAvailability: 'You can download najiz app',
    devicesText: 'for smart devices that works with android and iphon os',
  },

  useTheService: 'Use the service',
  startTheService: 'Start the Service',
  serviceDescription: 'Service description',
  serviceRequirements: 'Service requirements',
  stepsToApplyForTheService: 'Steps to apply for the service',
  submitNewRequest: 'Submit a new request',
  followUpOnPreviousRequest: 'Follow up on previous request',
  authenticationOfCompletedRequest: 'Authentication of completed request',
  serviceVideoExplaination: 'Service video explaination',
  serviceProviderChannels: 'Service provider channels',
  youShouldKnow: 'You should know',
  fetchingData: 'Fetching data in progress ...',
  searchResult: 'Search result',
  searchResults: 'Search results ({searchText})',
  browseService: 'Browse service',
  noSearchResultsFound: 'There are no results matching the data entered',
  noSearchResultsMessage: 'Please verify the data and try again.',

  login: 'Login',
  activateBusinessAccount: 'Activate business account',
  individuals: 'Individuals',
  businesses: 'Businesses',
  government: 'Government',
  lawyers: 'Lawyers',
  individualsLoginMsg: 'Login for individuals including citizens, residents and visitors',
  businessesLoginMsg: 'Login for owners and representatives of companies, institutions and public benefit facilities',
  governmentLoginMsg: 'Login for representatives of government agencies',
  lawyersLoginMsg: 'Login for lawyers licensed by the Ministry of Justice',
  forMoreDetails: 'For more details',
  requiredDocuments: 'Required documents',
  serviceExecutionTime: 'Service execution time',
  instant: 'Instant',
  serviceExecutionTimeDescription: 'From {from} - To {to}',
  beneficiaries: 'beneficiaries',
  serviceFees: 'Service fees',
  commonFaqs: 'Common faqs',
  checkFaqs: 'Check faqs',
  clickHere: 'Click here',
  signalSupport: 'Signal support',
  signalCustomerSupport: 'Signal customer support',
  allServices: 'All services',
  downloadApp: 'Download app',
  usageGuide: 'Usage guide',
  downloadUsageGuide: 'Download usage guide',
  theDescription: 'The description',
  theRequirements: 'The requirements',
  theSteps: 'The steps',
  share: 'share',
  serviceLinkCopiedSuccessfully: 'Service link copied successfully',
  loginToCreateAccount: 'login to create account',

  sessionsAttendence: 'attending sessions',
  ePayment: 'electronic payment',
  executionRequestActions: 'Actions on execution request',
  virtualCourtExecutionRequest: 'Virtual Court Execution Request',
  nowFromApp: 'Now From App',
  downloadAppNow: 'Download Najiz App Now',

  serverErrors: {
    USER_NOT_FOUND: 'User is not found, please try again',
    INVALID_DATA: 'INVALID DATA, please try again',
    SERVER_ERROR: 'Something went wrong, please try again later',
  },
};

export default ar;
