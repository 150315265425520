import { Component, Prop, Vue } from 'vue-property-decorator';
import { Inject } from 'vue-di-container';
import styles from './styles.module.scss';
import WithRender from './serviceCard.html';
// Models
import TelemetryService from '@/services/TelemetryService';
import { successfullEvent } from '@/utils/telemetry';
import { ServiceCardAction } from '@/models/components';

@WithRender
@Component
export default class ServiceCard extends Vue {
  @Inject(TelemetryService)
  public telemetryService!: TelemetryService;

  @Prop({ default: '' })
  public title!:string;

  @Prop({ default: '' })
  public description!:string;

  @Prop({ default: '' })
  public cardClassName!:string;

  @Prop({ default: '' })
  public image!:string;

  @Prop({ default: '' })
  public cardId!:string;

  @Prop({ default: '' })
  public cardActionId!:string;

  @Prop({ default: false })
  public clickable!:boolean;

  @Prop({ default: true })
  public enableActions!:boolean;

  @Prop({ default: false })
  public showImagePlaceholder!:boolean;

  @Prop({ default: '' })
  public iconName!: string;

  @Prop({ default: '' })
  public iconClass!: string;

  @Prop({
    default: () => ({
      class: '',
      label: '',
      link: '',
      isExternal: false,
      primary: true,
      ariaLabel: '',
    }),
  })
  public action!:ServiceCardAction;

  public get actionProps() {
    return {
      class: '',
      label: '',
      link: '',
      ariaLabel: '',
      isExternal: false,
      primary: true,
      ...this.action,
    };
  }

  public styles = styles;

  public onCardClick() {
    if (this.clickable) {
      this.onActionClick();
    }
  }

  public onActionClick() {
    const { link, isExternal } = this.action;
    this.pushTelemetryEventPerNavigation();

    if (isExternal) {
      const a = document.createElement('a');
      a.setAttribute('href', link || '');
      a.click();
    } else {
      this.$router.push(link || '').catch(() => true);
    }
  }

  private pushTelemetryEventPerNavigation() {
    this.telemetryService.trackEvent({
      ...successfullEvent,
      eventName: `${this.$t('government')}-${this.title}`,
      serviceType: 'Navigation',
    });
  }
}
