import {
  Component, Prop, Vue, Watch,
} from 'vue-property-decorator';

import WithRender from './n-modal.html';
import Teleport from 'vue2-teleport';

@WithRender
@Component({
  components: {
    Teleport,
  },
})
export default class NModal extends Vue {
  @Prop({ default: false })
  public open!: boolean;

  @Prop({ default: '' })
  public wrapperClass!: string;

  @Prop({ default: '' })
  public maxWidth!: string;

  @Prop({ default: '' })
  public maxHeight!: string;

  @Watch('open')
  public avoidScroll() {
    if (this.open) {
      document.body.classList.add('n-overflow-hidden');
    } else {
      document.body.classList.remove('n-overflow-hidden');
    }
  }

  public onCloseModal() {
    this.$emit('close');
  }
}
