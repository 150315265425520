// import { Inject } from 'vue-di-container';
import { Component, Vue } from 'vue-property-decorator';
import WithRender from './app.html';
// components
import Breadcrumb from '@/components/Breadcrumb/breadcrumb';

@WithRender
@Component({
  components: {
    Breadcrumb,
  },
})
export default class App extends Vue {
}
