import { ErrorHandler } from './errorHandler';
import { Service } from 'vue-di-container';
import http, { NajizHttpProxy, NajizHttpResponse } from '@moj/najiz-http';

@Service()
export default class HttpService {
  public httpInstance: NajizHttpProxy;
  constructor() {
    const config = {
      baseURL: process.env.BASE_URL,
      // timeout: 60 * 1000, // Timeout
      // withCredentials: true, // Check cross-site Access-Control
    };
    this.httpInstance = http.create(config);

    this.httpInstance.interceptors.request.use(
      (request) => {
        window.showLoader();

        return request;
      },
      (error) => ErrorHandler.errorResponseHandler(error),
    );

    // Add a response interceptor
    this.httpInstance.interceptors.response.use(
      (response:NajizHttpResponse) => {
        window.hideLoader();

        return response;
      },
      (error) => ErrorHandler.errorResponseHandler(error),
    );
  }
}
