import { SlideData } from '@/models/mobileApp';
import i18n from '@/plugins/i18n';

export const BreadcrumbId = 'app-breadcrumb';

export const LoginTypesIds = {
  individuals: 1,
  business: 2,
  governmental: 3,
  lawyers: 4,
};

export const MobileAppsSlides: SlideData[] = [
  {
    title: i18n.t('sessionsAttendence') as string,
    image: '/img/appSlide1.svg',
  },
  {
    title: i18n.t('ePayment') as string,
    image: '/img/appSlide2.svg',
  },
  {
    title: i18n.t('executionRequestActions') as string,
    image: '/img/appSlide3.svg',
  },
  {
    title: i18n.t('virtualCourtExecutionRequest') as string,
    image: '/img/appSlide4.svg',
  },
];
