import { trackEventConfigs } from '@/models/telemetry';
// enums
// import { serviceTypes } from '@/enums/telemetry';

const configs: trackEventConfigs = {
  userInfo: null,
  eventName: 'submit',
  serviceType: 'Verification',
  serviceNameEn: '',
  serviceNameAr: '',
  isSuccess: true,
};

const successfullEvent: trackEventConfigs = { ...configs };
const failedEvent: trackEventConfigs = { ...configs, isSuccess: false };

export {
  failedEvent,
  successfullEvent,
};
