<div :class="['n-align-baseline n-flex n-justify-between n-mb-8 n-modal-header', wrapperClass]">

  <slot>
    <div class="n-color-font-primary n-font-bold n-modal-title">
      {{ title }}
    </div>
  </slot>

  <slot name="dismiss" :click="onClose">
    <button @click="onClose" class="n-cursor-pointer">
      <n-icon name="n-icon-close-cross" size="24" />
    </button>
  </slot>
</div>