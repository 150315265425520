import { Toaster } from './toast';
import i18n from '@/plugins/i18n';

export class ErrorHandler {
  public static errorResponseHandler(error: any) {
    let message = '';

    const isSystemError = error?.response?.status === 500;
    message = isSystemError ? i18n.t('serverErrors.SERVER_ERROR') as string : error.response.data;

    window.hideLoader();

    if (message) {
      Toaster.error({ message });
    }

    return Promise.reject(error);
  }
}
