<div :id="breadcrumbId" v-if="showBreadCrumb" :class="['n-pt-5', styles.breadcrumbsWrapper, {'n-bg-primary-5': isHighlighted}]">
  <n-container>
    <v-breadcrumbs class="n-p-0" :items="breadcrumbs">
      <template v-slot:divider>
        <n-icon name="n-icon-arrow-left" size="16" />
      </template>
      <template v-slot:item="{ item }">
        <a
          :aria-label="item.text"
          v-if="item.external" 
          :href="item.href" 
          class="n-color-font-primary n-text-body-default"
          :disabled="item.disabled"
        >
          {{item.text}}
        </a>
  
        <v-breadcrumbs-item
          v-else
          :aria-label="item.text"
          :class="['n-color-font-primary n-text-body-default', styles.activeBreadcrumb, {[styles.disabled]: item.disabled}]"
          @click="onClickBreadcrumb(item)"
          :disabled="item.disabled"
        >
          {{item.text}}
        </v-breadcrumbs-item>
      </template>
    </v-breadcrumbs>
  </n-container>
</div>