import { Component, Prop, Vue } from 'vue-property-decorator';

import WithRender from './n-modal-header.html';

@WithRender
@Component({})
export default class NModalHeader extends Vue {
  @Prop({ default: '' })
  public wrapperClass!: string;

  @Prop({ default: '' })
  public title!: string;

  public onClose() {
    this.$emit('close');
  }
}
